import { find, get, includes, map, size } from "lodash";

// Find the localised text for an input argument and applies formatting based on some condition
const localiseText = (corpus, input) => {
  const entry = find(corpus, { reference: input });

  return entry;
};

const localisePages = (pages, translation) => {
  return map(pages, (page) => {
    return {
      name: page.name,
      // type: page.type,
      value: [
        {
          name: "content",
          value: page.content,
          data: localiseText(translation, page.content),
        },
      ],
    };
  });
};

const localiseQuestions = (questions, translation) => {
  return map(questions, (question) => {
    return {
      name: question.name,
      // type: question.type,
      value: [
        {
          name: "title",
          value: question.title,
          data: localiseText(translation, question.title),
        },
        {
          name: "description",
          value: question.description,
          data: localiseText(translation, question.description),
        },
        {
          name: "content",
          value: question.content,
          data: localiseText(translation, question.content),
        },
        {
          name: "options",
          value: get(question, "options", []),
          data: map(get(question, "options", []), (option) =>
            localiseText(translation, option)
          ),
        },
        {
          name: "groups",
          value: get(question, "groups", []),
          data: map(get(question, "groups", []), (group) =>
            localiseText(translation, group)
          ),
        },
        {
          name: "features",
          value: get(question, "features", []),
          data: map(get(question, "features", []), (feature) =>
            localiseText(translation, feature)
          ),
        },
        {
          name: "levels",
          value: get(question, "levels", []),
          data: map(get(question, "levels", []), (level) =>
            localiseText(translation, level)
          ),
        },
        {
          name: "labels",
          value: get(question, "labels", []),
          data: map(get(question, "labels", []), (label) =>
            localiseText(translation, label)
          ),
        },
      ],
    };
  });
};

const main = (survey_name, content, translation) => {
  const corpus = map(translation, (item) => ({
    content: item.content,
    referenceId: item._id,
    reference: item.reference,
    is_current: size(item.sources) === 1 && includes(item.sources, survey_name),
  }));

  const pages = get(content, "pages", []);
  const questions = get(content, "questions", []);

  return {
    pages: localisePages(pages, corpus),
    questions: localiseQuestions(questions, corpus),
  };
};

export default main;
