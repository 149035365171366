const {
  REACT_APP_AUTH_REGION,
  REACT_APP_AUTH_USER_POOL_ID,
  REACT_APP_AUTH_USER_POOL_CLIENT_ID,
  REACT_APP_SURVEY_API_URL,
  REACT_APP_EDITOR_API_URL,
  REACT_APP_TRANSLATION_API_URL,
} = process.env;

const config = {
  Auth: {
    region: REACT_APP_AUTH_REGION,
    userPoolId: REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AUTH_USER_POOL_CLIENT_ID,
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "surveys",
        endpoint: REACT_APP_SURVEY_API_URL,
      },
      {
        name: "editor",
        endpoint: REACT_APP_EDITOR_API_URL,
      },
      {
        name: "translations",
        endpoint: REACT_APP_TRANSLATION_API_URL,
      },
    ],
  },
};

export default config;
