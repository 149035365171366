import { Divider, Stack } from "@mui/material";
import TranslateContent from "./TranslateContent";
import ExportTranslations from "./exportTranslations/ExportTranslations";
import ImportTranslations from "./ImportTranslations";

const Translations = () => {
  return (
    <Stack
      sx={{
        flexGrow: 1,
        pt: 8,
      }}
      gap={4}
    >
      <TranslateContent />

      <Divider />

      <ExportTranslations />

      <Divider />

      <ImportTranslations />
    </Stack>
  );
};

export default Translations;
