import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useMemo, useState } from "react";
import useSurveysStore from "../../../../store/surveys";
import { API } from "aws-amplify";
import getJwt from "../../../../helpers/getJwt";
import extractor from "../../../../helpers/extractor";
import { find, map } from "lodash";
import { CloudUpload } from "@mui/icons-material";
import { AVAILABLE_LOCALES } from "../../../../helpers/constants";
import useBaseStore from "../../../../store/base";

const Localisation = () => {
  const currentSurvey = useSurveysStore((state) => state.currentSurvey);
  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  const [locales, setLocales] = useState([]);
  const [loader, setLoader] = useState(false);
  const [dialog, setDialog] = useState(false);

  const selectedLocales = useMemo(() => {
    return locales
      .map((locale) => AVAILABLE_LOCALES.find((l) => l.id === locale).value)
      .join(", ");
  }, [locales]);

  const handleLocalise = async () => {
    setLoader(true);

    try {
      const { pages, questions } = await API.get(
        "surveys",
        `/surveys/name/${currentSurvey.name}/exporter`
      ); // get survey data

      const templateData = extractor({ pages, questions }); // map the result to the appropriate format.

      const jwt = await getJwt();

      const localeDataRequests = locales.map((locale) =>
        API.get(
          "translations",
          `/localiser?locale=${locale}&source=${currentSurvey.name}`,
          {
            headers: { Authorization: `Bearer ${jwt}` },
          }
        )
      );

      const localeData = await Promise.all(localeDataRequests); // get the survey's contents for the selected locale from translations DB

      const localeDataTransformed = localeData.map((item) =>
        map(templateData, (templateItem) => {
          // for each item of the templateData
          const value = find(item, { reference: templateItem.value }); // find a matching value from the localised data
          return {
            name: templateItem.name,
            value: value ? value.content : templateItem.value, // return the localised value or provide the current (english) value as fallback
          };
        })
      );

      await Promise.all(
        locales.map((locale, index) =>
          API.put(
            "surveys",
            `/surveys/${currentSurvey.id}/locale/${locale}/importer`,
            {
              body: localeDataTransformed[index],
              headers: { Authorization: `Bearer ${jwt}` },
            }
          )
        )
      );

      setDialog(false);

      setSnackbar({
        open: true,
        type: "success",
        message: "Localised survey was uploaded!",
      });
    } catch (e) {
      console.error(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Error while uploading the localised survey",
      });
    }

    setLoader(false);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        pt: 8,
      }}
      gap={4}
    >
      <Stack
        sx={{ alignItems: "center", width: "500px", alignSelf: "center" }}
        direction="row"
        gap={2}
      >
        <FormControl size="small" sx={{ flexGrow: 1 }}>
          <InputLabel>Locales</InputLabel>

          <Select
            multiple
            value={locales}
            onChange={(e) => setLocales(e.target.value)}
            label="Locales"
            renderValue={(selected) =>
              selected
                .map(
                  (locale) =>
                    AVAILABLE_LOCALES.find((l) => l.id === locale).value
                )
                .join(", ")
            }
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "300px",
                },
              },
            }}
          >
            {AVAILABLE_LOCALES.map((locale) => (
              <MenuItem key={locale.id} value={locale.id}>
                <Checkbox checked={locales.includes(locale.id)} />
                <ListItemText primary={locale.value} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="outlined"
          disabled={!locales.length}
          onClick={() => setDialog(true)}
          sx={{ flexShrink: 0 }}
        >
          Upload localisation
        </Button>
      </Stack>

      <Dialog onClose={() => setDialog(false)} open={dialog}>
        <Stack sx={{ width: "600px", padding: 2 }} gap={2}>
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            You are about to upload a localised version of survey{" "}
            <strong>{currentSurvey.name}</strong> in{" "}
            <strong>{selectedLocales}</strong>
          </Alert>

          <LoadingButton
            loading={loader}
            variant="outlined"
            onClick={handleLocalise}
            startIcon={<CloudUpload />}
          >
            Upload
          </LoadingButton>
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default Localisation;
