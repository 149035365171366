import { get, map } from "lodash";

const main = (input) => {
  const pages = get(input, "pages", []);
  const questions = get(input, "questions", []);

  const pages_output = map(pages, (page) => ({
    name: page.name,
    // type: page.type,
    content: page.content,
  }));

  const questions_output = map(questions, (question) => {
    const options = map(question.options, (option) => option.text);
    const groups = map(question.groups, (group) => group.text);

    // TODO: multi option text should be defined with labels
    // const multi_options
    // const multi_groups

    const features = map(
      get(question, "features", []),
      (feature) => feature.text
    );
    const levels = map(get(question, "levels", []), (level) => level.text);

    const labels = map(get(question, "labels", []), (label) => label.value);

    return {
      name: get(question, "name", null),
      // type: question.type,

      title: get(question, "title", ""),
      description: get(question, "description", ""),
      content: get(question, "content", ""),

      options,
      groups,

      features,
      levels,

      labels,
    };
  });

  return { pages: pages_output, questions: questions_output };
};

export default main;
