import {
  Alert,
  Checkbox,
  Dialog,
  FormControl,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { AVAILABLE_LOCALES } from "../../../../../helpers/constants";
import { LoadingButton } from "@mui/lab";
import { API } from "aws-amplify";
import useSurveysStore from "../../../../../store/surveys";
import getJwt from "../../../../../helpers/getJwt";
import processSurvey from "./processSurvey";
import processTranslation from "./processTranslation";
import useBaseStore from "../../../../../store/base";

const ExportTranslations = () => {
  const currentSurvey = useSurveysStore((state) => state.currentSurvey);
  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  const [locales, setLocales] = useState([]);
  const [loader, setLoader] = useState(false);
  const [spreadsheetUrls, setSpreadsheetUrls] = useState([]);

  const getSurveyData = () => {
    return API.get("surveys", `/surveys/name/${currentSurvey.name}/exporter`);
  };

  const getTranslationData = async (locale) => {
    const jwt = await getJwt();

    return API.get(
      "translations",
      `/localiser?locale=${locale}&source=${currentSurvey.name}`,
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );
  };

  const exportTranlationsRequests = async ({ locale, survey, reviewData }) => {
    // create spreadsheet
    const jwt = await getJwt();

    const { spreadsheetId } = await API.post(
      "editor",
      "/translations/exporter/create-xls",
      {
        body: {
          locale,
          survey,
        },
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );

    const { pages, questions } = reviewData;

    return Promise.all([
      API.post("editor", "/translations/exporter", {
        body: {
          spreadsheetId,
          xlsData: questions,
          sheet: "questions",
        },
        headers: { Authorization: `Bearer ${jwt}` },
      }),
      API.post("editor", "/translations/exporter", {
        body: {
          spreadsheetId,
          xlsData: pages,
          sheet: "pages",
        },
        headers: { Authorization: `Bearer ${jwt}` },
      }),
    ]);
  };

  const exportTranlations = async () => {
    setSpreadsheetUrls([]);
    setLoader(true);

    try {
      const { pages, questions } = await getSurveyData();

      const surveyOutput = processSurvey({
        pages,
        questions,
      });

      const translationResponses = await Promise.all(
        locales.map((locale) => getTranslationData(locale))
      );

      const reviewDataPerLocale = translationResponses.map(
        (translationResponse) =>
          processTranslation(
            currentSurvey.name,
            surveyOutput,
            translationResponse
          )
      );

      const reviewResponses = await Promise.all(
        reviewDataPerLocale.map((reviewData, index) =>
          exportTranlationsRequests({
            locale: locales[index],
            survey: currentSurvey.name,
            reviewData,
          })
        )
      );

      const spreadsheetUrls = reviewResponses.map((response, index) => ({
        url: response[0].url,
        locale: AVAILABLE_LOCALES.find((l) => l.id === locales[index]).value,
      }));

      setSpreadsheetUrls(spreadsheetUrls);
    } catch (e) {
      setSnackbar({
        open: true,
        type: "error",
        message: "Error while generating spreadsheets",
      });
    }

    setLoader(false);
  };

  return (
    <Stack sx={{ alignItems: "center" }} gap={2}>
      <Stack sx={{ width: "500px" }} gap={2}>
        <Stack sx={{ alignItems: "center" }} direction="row" gap={2}>
          <FormControl size="small" sx={{ flexGrow: 1 }}>
            <InputLabel>Locales</InputLabel>

            <Select
              multiple
              value={locales}
              onChange={(e) => setLocales(e.target.value)}
              label="Locales"
              renderValue={(selected) =>
                selected
                  .map(
                    (locale) =>
                      AVAILABLE_LOCALES.find((l) => l.id === locale).value
                  )
                  .join(", ")
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "300px",
                  },
                },
              }}
            >
              {AVAILABLE_LOCALES.map((locale) => (
                <MenuItem key={locale.id} value={locale.id}>
                  <Checkbox checked={locales.includes(locale.id)} />
                  <ListItemText primary={locale.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <LoadingButton
            variant="outlined"
            sx={{ flexShrink: 0 }}
            loading={loader}
            disabled={!locales.length}
            onClick={exportTranlations}
          >
            2. Export Translations
          </LoadingButton>
        </Stack>

        <Alert severity="info">
          This will generate a spreadsheet for each selected language to review.
        </Alert>
      </Stack>

      {Boolean(spreadsheetUrls.length) && (
        <Alert
          severity="success"
          onClose={() => {
            setSpreadsheetUrls([]);
          }}
        >
          <Stack>
            {spreadsheetUrls.map(({ url, locale }) => (
              <Stack
                direction="row"
                sx={{ alignItems: "center" }}
                gap={1}
                key={url}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {locale}
                </Typography>

                <Typography variant="body2">-</Typography>

                <Typography
                  variant="body2"
                  component="a"
                  href={url}
                  target="_blank"
                >
                  {url}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Alert>
      )}

      <Dialog open={loader}>
        <Stack sx={{ width: "600px", padding: 2 }} gap={2}>
          <Alert severity="info" sx={{ fontWeight: "bold" }}>
            Please wait while spreadsheets are being generated. It may take a
            minute.
          </Alert>

          <LinearProgress />
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default ExportTranslations;
